import React, { useState, useEffect } from 'react';
import './../../Styles/leadstyles/ViewLeads.css';
const apiUrl = process.env.REACT_APP_API_URL;

const ViewLeads = ({ managerId }) => {
  const [leads, setLeads] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    // Fetch leads when the component mounts
    fetchLeads();
  }, [managerId]);

  const fetchLeads = () => {
    fetch(`${apiUrl}/api/view-leads?managerId=${managerId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setLeads(data);
        console.log('Fetched leads:', data); // Debugging output to check leads data structure
      })
      .catch((error) => {
        console.error('Error fetching leads:', error);
        setError('Error fetching leads. Please try again.');
      });
  };

  const handleChangeRole = (employeeNumber) => {
    console.log('Changing role for lead ID:', employeeNumber); // Debugging output

    if (!employeeNumber) {
      alert('Invalid lead ID');
      return;
    }

    fetch(`${apiUrl}/api/change-lead-role/${employeeNumber}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to change role');
        }
        return response.json();
      })
      .then((data) => {
        alert(data.message); // Show success message

        // Refresh leads to reflect the updated role
        fetchLeads();
      })
      .catch((error) => {
        console.error('Error changing lead role:', error);
        alert('Error changing lead role. Please try again.');
      });
  };

  return (
    <div className="view-leads">
      <h3>View Leads</h3>

      {error && <div className="error-message">{error}</div>}

      {leads.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Employee Number</th>
              <th>Full Name</th>
              <th>Job Title</th>
              <th>Project</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {leads.map((lead) => (
              <tr key={lead.employee_number}>
                <td>{lead.employee_number}</td>
                <td>{lead.full_name}</td>
                <td>{lead.job_title}</td>
                <td>{lead.project_name || 'N/A'}</td>
                <td>
                  <button onClick={() => handleChangeRole(lead.employee_number)}>Remove</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No leads available.</p>
      )}
    </div>
  );
};

export default ViewLeads;
